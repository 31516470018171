.four-point-star::after {
    content: "\2726";
    font-size: 30px;
    background-image: linear-gradient(135deg, rgb(43, 0, 255), rgb(255, 0, 34), rgb(3, 255, 24));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient-animation 5s linear infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}