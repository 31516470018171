.recording-effect {
    position: relative;
    display: inline-block;
    padding: 10px;
    border-radius: 50%;
    background-color: #ff0000;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.text-output {
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    color: #333333;
    margin-top: 10px;
    padding: 10px;
    background-color: #f5f5f5;
}