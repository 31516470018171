.user {
    background-color: #4169E1;
    color: #FFFFFF;
    align-self: flex-end;
    margin-bottom: 10px;
    position: relative;
}

.assistant {
    background: linear-gradient(45deg, #FF0000, #FF4500);
    color: #FFFFFF;
    align-self: flex-start;
    margin-bottom: 10px;
    position: relative;
}

.message {
    margin: 0;
    font-size: 14px;
}

.user::before,
.assistant::before {
    content: '';
    position: absolute;
    bottom: -9px;
    width: 0;
    height: 0;
    border-style: solid;
}

.user::before {
    border-color: transparent transparent #4169E1 transparent;
    right: 12px;
    border-width: 9px 9px 0 9px;
}

.assistant::before {
    border-color: transparent transparent #FF0000 transparent;
    left: 12px;
    border-width: 9px 9px 0 9px;
}

.chat-bubble {
    opacity: 0;
    transform: translateY(20px);
    animation-name: slideIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    border-radius: 12px;
    background-color: #434343;
    color: #fff;
    font-size: 16px;
    padding: 10px 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: inline-block;
}

.chat-bubble.user {
    background-color: #007bff;
    border-bottom-right-radius: 2px;
}

.chat-bubble.assistant {
    background: linear-gradient(45deg, #FF0000, #FF4500);
    border-bottom-left-radius: 2px;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* ... previous styles ... */

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 2px;
    background-color: #fff;
    animation-name: dotAnimation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.dot1 {
    animation-delay: 0s;
}

.dot2 {
    animation-delay: 0.2s;
}

.dot3 {
    animation-delay: 0.4s;
}

@keyframes dotAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}