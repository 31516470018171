.audio-player {
    display: inline-block;
}

.play-pause-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
}

.play-pause-button i {
    font-size: 20px;
}

.play-pause-button.playing i:before {
    content: '\f28b';
    /* Pause icon (Font Awesome) */
}

.loading-indicator {
    font-size: 14px;
    color: #888;
}