/* ... previous styles ... */

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: "50%"
}

.gradient-container {
    width: 60px;
    height: 60px;
    position: relative;
}

.gradient {
    position: absolute;
    background: linear-gradient(45deg, #000000, #FF0000, #0000FF);
    background-size: 200% 200%;
    animation: gradientAnimation 5s ease-in-out infinite;
    border-radius: 14px;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}